import "./index.css";
import NavBar from "../components/NavBar";
const LandingPage = () => {
  return (
    <>
      <NavBar />
      <div className="text-red-600 text-center text-[10vw] select-none">
        Landing Page!!
      </div>
    </>
  );
};

export default LandingPage;

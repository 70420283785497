import { Link } from "react-router-dom";

const Project = () => {
  return (
    <div className="relative inline-block text-left group">
      <button
        className="inline-flex justify-center w-full px-10 py-2 text-[2vw]
        text-white hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
      >
        Project
      </button>
      <div className="absolute left-0 w-56 mt-2 origin-top-left bg-slate-600 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-1000 ease-in-out">
        <div className="py-1">
          <Link
            to="/project/genshinrandomizer"
            className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
          >
            Genshin Randomizer
          </Link>
          <Link
            to="https://real-time-chat-01.web.app/"
            className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
          >
            Real Time Chat
          </Link>
          <Link
            to="https://todo-list-kiyang101.vercel.app/"
            className="group flex items-center px-4 py-2 text-[1vw] text-white hover:bg-[rgba(148,163,184,.5)] w-full text-left duration-500 ease-in-out shadow-2xl"
          >
            To-Do List
          </Link>
        </div>
      </div>
    </div>
  );
};

const NavBar = () => {
  return (
    <>
      <div className="bg-slate-500 h-[9vh] sticky top-0 flex items-center z-20">
        <div className="flex items-center w-full px-5 text-white text-[2vw]">
          <div className="flex-none">
            <Link to="/" className="">
              Kiyang.net
            </Link>
          </div>
          <div className="flex-1 flex justify-center items-center gap-5">
            <Link
              to="/profile"
              className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
            >
              Profile
            </Link>
            <Project />
            <a
              href="https://github.com/Kiyang101"
              className="px-10 py-2 hover:bg-[rgba(148,163,184,.5)] rounded-xl ease-in-out duration-500"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
